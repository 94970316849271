<template>
  <AddEntityForm></AddEntityForm>
</template>

<script>
import AddEntityForm from '@/components/datasources/AddEntityForm';

export default {
  name: 'CustomSource',
  components: { AddEntityForm },
};
</script>

<style scoped>

</style>
