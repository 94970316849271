<template>
  <v-container>
    <v-row>
      <v-text-field
        label="Id"
        id="id"
        name="id"
        type="text"
        v-model.trim="entity.id"
        :rules="[rules.required]"
        prepend-icon="plus_one"
      />
    </v-row>
    <v-row>
      <v-text-field
        label="Name"
        id="name"
        name="name"
        type="text"
        v-model.trim="entity.fullName"
        :rules="[rules.required]"
        prepend-icon="person"
      />
    </v-row>
    <v-row>
      <v-radio-group
        label="Entity type"
        id="entityType"
        name="entityType"
        v-model.trim="entity.entityType"
        :rules="[rules.required]"
        prepend-icon="nature_people"
        row
      >
        <v-radio label="Person" value="I"/>
        <v-radio label="Company" value="C"/>
        <v-radio label="Unknown" value="U"/>
      </v-radio-group>
    </v-row>
    <v-row>
      <v-text-field
        label="Aliases"
        id="aliases"
        name="aliases"
        type="text"
        v-model.trim="entity.aliases"
        prepend-icon="group"
      />
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete label="Countries"
                        :items="countryList"
                        v-model="entity.country"
        />
      </v-col>
      <v-col>
        <v-autocomplete label="Birth year"
                        :items="years"
                        v-model="entity.yearsOfBirth"
        />
      </v-col>
    </v-row>
    <v-autocomplete label="Target data source"
                    :items="dataSourceList"
                    v-model="dataSource"
                    :rules="[rules.required]"
                    :item-disabled="(item) => (item.value !== 'user_custom_list')"
    />
    <v-row class="pt-5 pb-5">
      <v-btn color="primary" @click="addEntity">Add to data source</v-btn>
    </v-row>
    <v-row v-if="response">
      <VueJsonPretty :data="response" :deep="1"/>
    </v-row>
  </v-container>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';
import { required, minLen } from '@/utils/validations';
import { lifeYearRange } from '@/utils/dates';

// import EntityDetails from '@/components/entity/EntityDetails';

export default {
  components: { VueJsonPretty },
  computed: {
    ...mapGetters(['dataSourceList', 'countryList']),
    years: lifeYearRange,
  },
  data() {
    return {
      rules: {
        required,
        minLen,
      },
      entity: {
        id: null,
        fullName: null,
        entityType: 'U',
        aliases: null,
        country: null,
        yearsOfBirth: null,
      },
      dataSource: null,
      response: null,
      indexedEntity: null,
    };
  },
  methods: {
    ...mapActions({
      loadDataSources: 'READ_LIST_dataSource',
      loadCountries: 'READ_LIST_country',
    }),
    async addEntity() {
      this.response = await this.$indexClient.index({
        entity: this.entity,
        target: { id: this.dataSource },
      });

      // if (this.response) {
      //   const { _id: uniqueEntityId } = this.response?.data[0]?.body;
      //   if (uniqueEntityId) {
      //     this.indexedEntity = await this.$apiClient.getEntity(uniqueEntityId);
      //   }
      // }
    },
  },
  created() {
    this.loadCountries();
    this.loadDataSources();
  },
};
</script>

<style scoped>

</style>
